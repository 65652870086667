import noCompanyLogo from "~/assets/companies/default-company-logo.svg";
import { getImageUrl } from "~/utils/logodev";
import type { PostLoader } from "../types";

interface IPostSEO {
  loaderData: PostLoader;
}

export default function PostSEO({ loaderData: { post, url, companyProfile, logoDevApiKey } }: IPostSEO) {
  // Use http for localhost, https otherwise
  if (url.includes("localhost")) {
    url = "http://" + url;
  } else {
    url = "https://" + url;
  }

  // Fallback or retrieved logo
  const imageUrl = getImageUrl(companyProfile, logoDevApiKey, noCompanyLogo);

  const script: Record<string, any> = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    headline: post?.title,
    description: post?.previewContent,
    image: post?.thumbnail?.url,
    datePublished: post?.publishedAt,
    dateModified: post?.updatedAt,
    author: {
      "@type": "Organization",
      name: "G2Xchange",
    },
    publisher: {
      "@type": "Organization",
      name: "G2Xchange",
      logo: {
        "@type": "ImageObject",
        url: post?.thumbnail?.url,
      },
    },
  };

  // If there's no `post.content`, treat it as paid content
  if (!post?.content) {
    script.isAccessibleForFree = false;
    script.hasPart = {
      "@type": "WebPageElement",
      isAccessibleForFree: false,
    };
  }

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(script) }} />;
}
