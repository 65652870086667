import { useLocation } from "@remix-run/react";
import { AnimatePresence, motion } from "framer-motion";
import { Index } from "react-instantsearch";
import { Flex } from "sparta";
import { useRootLoaderData } from "../../root";
import Awards from "./awards";
import Companies from "./companies";
import DailyTake from "./dailyTake";
import Opportunities from "./opportunities";

export default function ResultsData({
  selectedTab,
  dailyTakeLink,
  companiesLink,
  opportunitiesLink,
  opportunitiesData,
  opportunitiesLoading,
  awardsLink,
  awardsData,
  awardsLoading,
}) {
  const { canSeeOpportunitiesAtSearch, canSeeAwardsAtSearch } = useRootLoaderData();
  const location = useLocation();

  const getComponentOrder = () => {
    const pathname = location.pathname;
    if (pathname.includes("/companies")) {
      return ["companies", "dailyTake", "opportunities", "awards"];
    }
    if (pathname.includes("/opportunities")) {
      return ["opportunities", "dailyTake", "companies", "awards"];
    }
    // Default order for home, /search_v2 and other routes
    return ["dailyTake", "companies", "opportunities", "awards"];
  };

  const componentOrder = getComponentOrder();

  return (
    <Flex id="all_categories" direction="column" py="0" gap="0">
      {
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedTab ? selectedTab : "empty"}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {componentOrder.map((component) => {
              switch (component) {
                case "dailyTake":
                  return (
                    <Index key="dailyTake" indexName="posts">
                      <DailyTake
                        visible={selectedTab === "All Categories" || selectedTab === "Curated Market Intelligence"}
                        link={dailyTakeLink}
                        isLoading={status === "loading"}
                      />
                    </Index>
                  );
                case "companies":
                  return (
                    <Index key="companies" indexName="sam_entities">
                      <Companies
                        visible={selectedTab === "All Categories" || selectedTab === "Companies"}
                        link={companiesLink}
                        isLoading={status === "loading"}
                      />
                    </Index>
                  );
                case "opportunities":
                  return (
                    canSeeOpportunitiesAtSearch && (
                      <Opportunities
                        key="opportunities"
                        link={opportunitiesLink}
                        data={opportunitiesData?.results || []}
                        isLoading={!!opportunitiesLoading || false}
                        visible={selectedTab === "All Categories" || selectedTab === "Opportunities"}
                      />
                    )
                  );
                case "awards":
                  return (
                    canSeeAwardsAtSearch && (
                      <Awards
                        key="awards"
                        link={awardsLink}
                        data={awardsData?.results || []}
                        isLoading={!!awardsLoading || false}
                        visible={selectedTab === "All Categories" || selectedTab === "Awards"}
                      />
                    )
                  );
                default:
                  return null;
              }
            })}
          </motion.div>
        </AnimatePresence>
      }
    </Flex>
  );
}
