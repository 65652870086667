import { useEffect } from "react";
import { Badge, Flex, Skeleton, Tabs } from "sparta";
import { useRootLoaderData } from "../../root";

const numberFormatter = new Intl.NumberFormat("en-US");

export default function TabsData({
  tabClick,
  dailyTakeCounter,
  companiesCounter,
  opportunitiesData,
  awardsData,
  opportunitiesLoading,
  awardsLoading,
  selectedTab,
  setSelectedTab,
}) {
  const { canSeeOpportunitiesAtSearch, canSeeAwardsAtSearch } = useRootLoaderData();

  const restartDefaultTab = ({ counter, tabName }: { counter: number; tabName: string }) => {
    if (counter === 0 && selectedTab === tabName) {
      setSelectedTab("All Categories");
    }
  };

  useEffect(() => {
    restartDefaultTab({ counter: dailyTakeCounter, tabName: "Curated Market Intelligence" });
    restartDefaultTab({ counter: companiesCounter, tabName: "Companies" });
    restartDefaultTab({ counter: opportunitiesData?.count || 0, tabName: "Opportunities" });
    restartDefaultTab({ counter: awardsData?.count || 0, tabName: "Awards" });
  }, [dailyTakeCounter, companiesCounter, opportunitiesData, awardsData]);

  return (
    <Tabs.Root
      defaultValue={"All Categories"}
      value={selectedTab}
      onValueChange={(value) => tabClick(value)}
      style={{ width: "100%", backgroundColor: "var(--color-background)" }}
    >
      <Tabs.List style={{ width: "100%", justifyContent: "start", alignItems: "center" }} size="1">
        <Flex gap="2" align="center" justify="start">
          <Skeleton style={{ maxWidth: "160px" }} loading={status === "loading"}>
            <Tabs.Trigger value={"All Categories"} onClick={(e) => e.stopPropagation()}>
              <Flex align={"center"}>
                All Categories
                {dailyTakeCounter + companiesCounter + (opportunitiesData?.count || 0) + (awardsData?.count || 0) >
                  0 && (
                  <Badge color="blue" radius="full" variant="solid">
                    {numberFormatter.format(
                      dailyTakeCounter + companiesCounter + (opportunitiesData?.count || 0) + (awardsData?.count || 0),
                    )}
                  </Badge>
                )}
              </Flex>
            </Tabs.Trigger>
          </Skeleton>
          <Skeleton style={{ maxWidth: "160px" }} loading={status === "loading"}>
            <Tabs.Trigger
              style={{ display: dailyTakeCounter === 0 ? "none" : "block" }}
              value={"Curated Market Intelligence"}
              onClick={(e) => e.stopPropagation()}
            >
              <Flex align={"center"}>
                Curated Market Intelligence
                {dailyTakeCounter > 0 && (
                  <Badge color="blue" radius="full" variant="solid">
                    {numberFormatter.format(dailyTakeCounter)}
                  </Badge>
                )}
              </Flex>
            </Tabs.Trigger>
          </Skeleton>
          <Skeleton style={{ maxWidth: "160px" }} loading={status === "loading"}>
            <Tabs.Trigger
              style={{ display: companiesCounter === 0 ? "none" : "block" }}
              value={"Companies"}
              onClick={(e) => e.stopPropagation()}
            >
              <Flex align={"center"}>
                Companies
                {companiesCounter > 0 && (
                  <Badge color="blue" radius="full" variant="solid">
                    {numberFormatter.format(companiesCounter)}
                  </Badge>
                )}
              </Flex>
            </Tabs.Trigger>
          </Skeleton>
          {canSeeOpportunitiesAtSearch && (
            <Skeleton style={{ maxWidth: "160px" }} loading={opportunitiesLoading}>
              <Tabs.Trigger
                style={{ display: opportunitiesData?.count === 0 ? "none" : "block" }}
                value={"Opportunities"}
                onClick={(e) => e.stopPropagation()}
              >
                <Flex align={"center"}>
                  Opportunities
                  {opportunitiesData?.count > 0 && (
                    <Badge color="blue" radius="full" variant="solid">
                      {numberFormatter.format(opportunitiesData?.count || 0)}
                    </Badge>
                  )}
                </Flex>
              </Tabs.Trigger>
            </Skeleton>
          )}
          {canSeeAwardsAtSearch && (
            <Skeleton style={{ maxWidth: "160px" }} loading={awardsLoading}>
              <Tabs.Trigger
                style={{ display: awardsData?.count === 0 ? "none" : "block" }}
                value={"Awards"}
                onClick={(e) => e.stopPropagation()}
              >
                <Flex align={"center"}>
                  Awards
                  {awardsData?.count > 0 && (
                    <Badge color="blue" radius="full" variant="solid">
                      {numberFormatter.format(awardsData?.count || 0)}
                    </Badge>
                  )}
                </Flex>
              </Tabs.Trigger>
            </Skeleton>
          )}
        </Flex>
      </Tabs.List>
    </Tabs.Root>
  );
}
