import { Link, useLocation } from "@remix-run/react";
import { Button } from "sparta";

interface MenuButtonProps {
  icon: React.ReactNode;
  title: React.ReactNode | ((active: boolean) => React.ReactNode);
  to: string;
  match?: string[];
  newTab?: boolean; // New prop to control whether the link opens in a new tab
  onClick?: () => void;
}

export function MenuButton({ icon, title, to, match, newTab = false, onClick }: MenuButtonProps) {
  const location = useLocation();
  const active = match ? [...match, to].includes(location.pathname) : location.pathname === to;

  const activeStyle = {
    padding: "var(--space-1) var(--space-2)",
    margin: "calc(0px - var(--space-1)) calc(0px - var(--space-2))",
    height: "unset",
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    boxSizing: "unset" as any,
  };

  const handleClick = (e: React.MouseEvent) => {
    onClick?.();
  };

  return (
    <Link
      to={to}
      prefetch="intent"
      target={newTab ? "_blank" : undefined} // Open in new tab if newTab is true
      rel={newTab ? "noopener noreferrer" : undefined} // Add rel attribute for security when opening in a new tab
      onClick={handleClick}
    >
      <Button
        style={{
          width: "100%",
          justifyContent: "flex-start",
          gap: "var(--space-2)",
          ...(active ? activeStyle : {}),
        }}
        variant={active ? "solid" : "ghost"}
        leftIcon={icon}
        color="gray"
        highContrast
      >
        {/* Wrap title and BetaBadge in a container */}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: 1, // Allow the container to take up remaining space
          }}
        >
          {typeof title === "function" ? title(active) : title}
        </span>
      </Button>
    </Link>
  );
}
