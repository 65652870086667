import React from "react";
import type { EventLoader } from "../types";

interface IEventSEO {
  loaderData: EventLoader;
}

export default function EventSEO({ loaderData: { event, url } }: IEventSEO) {
  // 1. Construct the base URL
  const protocol = url?.includes("localhost") ? "http://" : "https://";
  const fullUrl = `${protocol}${url}`;

  // 2. Determine eventAttendanceMode
  let eventAttendanceMode = "https://schema.org/OfflineEventAttendanceMode";
  if (event.isVirtual) {
    const locationIsOnline = typeof event.location === "string" && /online|on-line/i.test(event.location);

    if (event.location && !locationIsOnline) {
      // Virtual with a physical location (Mixed)
      eventAttendanceMode = "https://schema.org/MixedEventAttendanceMode";
    } else {
      // Purely virtual
      eventAttendanceMode = "https://schema.org/OnlineEventAttendanceMode";
    }
  }

  // 3. Construct location object
  let locationSchema: Record<string, any> | undefined = undefined;

  if (event.isVirtual && !event.location) {
    // Purely virtual
    locationSchema = {
      "@type": "VirtualLocation",
      url: event.registrationLink,
    };
  } else if (event.isVirtual && event.location && !/online|on-line/i.test(event.location)) {
    // Mixed (virtual and physical)
    locationSchema = {
      "@type": "Place",
      name: event.location || "TBD",
    };
  } else {
    // Offline
    locationSchema = {
      "@type": "Place",
      name: event.location || "TBD",
    };
  }

  // 4. Construct the offers array or object
  let offers: any;

  if (event.isVirtual && event.location && !/online|on-line/i.test(event.location) && !event.isFree) {
    // Mixed scenario => multiple offers
    offers = [
      {
        "@type": "Offer",
        url: event.registrationLink,
        category: "online",
      },
      {
        "@type": "Offer",
        url: event.registrationLink,
        category: "in-person",
      },
    ];
  } else {
    // Single “standard” offer
    offers = {
      "@type": "Offer",
      url: event.registrationLink,
      availability: "https://schema.org/InStock",
      category: "registration",
    };
  }

  // 5. Construct the JSON-LD
  const script: Record<string, any> = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: event.title,
    startDate: event.startDate,
    endDate: event.endDate,
    description: event.stringifiedContent,
    image: [event.thumbnail?.url].filter(Boolean),
    eventStatus: "https://schema.org/EventScheduled",
    eventAttendanceMode,
    location: locationSchema,
    organizer: {
      "@type": "Organization",
      name: event.organizer || "GBEF",
      url: event.registrationLink,
    },
    offers,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": fullUrl,
    },
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(script) }} />;
}
