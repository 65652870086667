import { useEffect, useState } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { Flex } from "sparta";
import useSWR from "swr";

import { fetcher } from "~/utils/fetcher";
import ResultsData from "./ResultsData";
import TabsData from "./TabsData";

export default function OverlayContent() {
  // Get Query applied
  const { query } = useSearchBox();

  // Get typesense results for posts, companies and people

  const { scopedResults, status } = useInstantSearch();

  const dailyTakeLink: string = `/search_v2?posts%5Bquery%5D=${query}`;
  const companiesLink: string = `/companies/search?%5Bquery%5D=${query}`;

  const findByIndexName = (indexName: string, arr: any[]) => arr.find((x) => x.indexId === indexName);

  // Define Opportunities data

  const opportunitiesLink: string = `/opportunities?search=${query}&page=1&limit=10`;

  const { data: opportunitiesData, isLoading: opportunitiesLoading } = useSWR(`/api${opportunitiesLink}`, fetcher);
  // Get client-side data from api route for awards and define its data

  const awardsLink: string = `/contracts`;

  const { data: awardsData, isLoading: awardsLoading } = useSWR(`/api${awardsLink}`, fetcher);

  // Format typensense results into counters

  const [dailyTakeCounter, setDailyTakeCounter] = useState<number>(0);
  const [companiesCounter, setCompaniesCounter] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>("All Categories");

  useEffect(() => {
    const daily_take_counter: number = !!findByIndexName("posts", scopedResults)?.results?.nbHits
      ? findByIndexName("posts", scopedResults)?.results?.nbHits
      : 0;
    const companies_counter: number = !!findByIndexName("sam_entities", scopedResults)?.results?.nbHits
      ? findByIndexName("sam_entities", scopedResults)?.results?.nbHits
      : 0;
    setDailyTakeCounter(daily_take_counter);
    setCompaniesCounter(companies_counter);
  }, [status]);

  const debounce = (func: Function, delay: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const handleTabClickDebounced = debounce((value: string) => {
    setSelectedTab(value);
  }, 300);

  return (
    <Flex width="100%" direction="column">
      <Flex direction="column">
        <Flex width={"100%"}>
          <TabsData
            tabClick={handleTabClickDebounced}
            dailyTakeCounter={dailyTakeCounter}
            companiesCounter={companiesCounter}
            opportunitiesData={opportunitiesData}
            awardsData={awardsData}
            opportunitiesLoading={opportunitiesLoading}
            awardsLoading={awardsLoading}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Flex>
        <Flex
          maxHeight="calc(100vh - 140px)"
          height="calc(100vh - 140px)"
          id="advanced_search"
          direction="column"
          overflowY="auto"
        >
          <ResultsData
            selectedTab={selectedTab}
            dailyTakeLink={dailyTakeLink}
            companiesLink={companiesLink}
            opportunitiesLink={opportunitiesLink}
            opportunitiesData={opportunitiesData}
            opportunitiesLoading={opportunitiesLoading}
            awardsLink={awardsLink}
            awardsData={awardsData}
            awardsLoading={awardsLoading}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
