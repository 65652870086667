import { type LinksFunction, type LoaderFunction, type MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useNavigate,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { SpartaProvider } from "sparta";

import { captureRemixErrorBoundaryError, setUser, withSentry } from "@sentry/remix";
import navigationBarStyle from "~/style/components/NavigationBar.css?url";
import global from "~/style/global.css?url";

import favicon from "~/assets/favicon.ico";
import exportBarStyle from "~/style/components/ExportBar.css?url";
import exportButtonStyle from "~/style/components/ExportButton.css?url";
import linkButtonStyle from "~/style/components/LinkButton.css?url";
import postModuleStyle from "~/style/components/PostModule.css?url";
import webBlockStyle from "~/style/components/WebBlock.css?url";

import { ApolloProvider } from "@apollo/client/index.js";
import { useEffect, useRef, useState } from "react";
import Footer from "./components/root/Footer";
import ServerError from "./components/root/ServerError";
import { getEnv } from "./env.server";
import { apolloClient } from "./utils/graphql/apollo-client";

import posthog from "posthog-js";
import paginationStyle from "~/style/components/Pagination.css?url";
import OverlaySearch from "./components/globalSearch/main";
import StateBanner, { stateBannerStyle } from "./components/layout/StateBanner";
import { AppLayout } from "./components/root/AppLayout/AppLayout";
import { Search } from "./components/search/Search";
import { FingerprintProvider } from "./context/FingerprintContext";
import PostSEO from "./routes/$platform.posts.$postId/components/PostSEO";
import { PostLoader } from "./routes/$platform.posts.$postId/types";
import CompanySEO from "./routes/companies.$companyId/components/CompanySEO";
import EventSEO from "./routes/events.$eventId/components/EventSEO";
import { EventLoader } from "./routes/events.$eventId/types";
import { PlatformClass } from "./sdk/qs1/Platform";
// @ts-ignore
import type { Platform, Subscription, User } from "./sdk/qs1/index.server";
import { Pipeline, Post } from "./sdk/qs1/index.server";
import { withSession } from "./sdk/qs1/wrappers";
import { checkFlag } from "./utils/featureFlags";
import { initializeLDClient } from "./utils/featureFlags.client";
import gtm, { analytics } from "./utils/gtm";
import useCatchLog from "./utils/hooks/useCatchLog";
import { setupIntercom } from "./utils/intercom";
import { getSessionToken } from "./utils/session.server";

export const links: LinksFunction = () => {
  return [
    { rel: "icon", href: favicon },
    { rel: "stylesheet", href: global },
    { rel: "stylesheet", href: navigationBarStyle },
    { rel: "stylesheet", href: webBlockStyle },
    { rel: "stylesheet", href: linkButtonStyle },
    { rel: "stylesheet", href: postModuleStyle },
    { rel: "stylesheet", href: exportBarStyle },
    { rel: "stylesheet", href: exportButtonStyle },
    { rel: "stylesheet", href: stateBannerStyle },
    { rel: "stylesheet", href: paginationStyle },
  ];
};

export const meta: MetaFunction = ({ params }) => {
  let platform = params?.platform;
  // display platform name in title
  platform = PlatformClass.toPlatform(platform, "");

  return [
    { charSet: "utf-8" },
    { title: `G2X ${platform}` },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1",
    }, // Updated here
    { name: "theme-color", content: "#0e214b" },
  ];
};

interface Context {
  user?: User | null;
  agencies?: string[];
  ENV: any;
}

interface LoaderData {
  user: User | null;
  ENV: any;
  userIsActive: boolean;
  platform: Platform | null;
  activePlatforms: Platform[];
  isloggedin: boolean;
  token: string;
  canSeeAwards: boolean;
  canSeePipelines: boolean;
  canSeeFestiveLogo: boolean;
  pipelines?: Pipeline[];
  canSeeOpportunitiesAtSearch: boolean;
  canSeeAwardsAtSearch: boolean;
  canSeeGlobalSearch: boolean;
  canFilterContracts: boolean;
  canSeeDogeTracker: boolean;
  canSeePSC: boolean;
  canSeeNAICS: boolean;
}
export const loader: LoaderFunction = withSession(async ({ request }, session): Promise<Context> => {
  const startTime = performance.now();
  const measurements: { operation: string; duration: number }[] = [];

  const url = new URL(request.url);
  const pathname = url.pathname;

  // Check if URL matches pattern: /{PLATFORM}/events/{EVENT_SLUG}
  const match = pathname.match(/^\/[^/]+\/events\/([^/]+)/);
  if (match) {
    const eventSlug = match[1];
    // Redirect to /events/{EVENT_SLUG}
    throw new Response(null, {
      status: 302,
      headers: {
        Location: `/events/${eventSlug}`,
      },
    });
  }

  const envStart = performance.now();
  const env = getEnv();
  const isLDEnabled = env.ENABLE_LD === "true";
  const deployEnv = url.hostname.split(".")?.[0] ?? "localhost";
  const platform = session.detectPlatform();
  measurements.push({
    operation: "Environment setup",
    duration: performance.now() - envStart,
  });

  const flagStart = performance.now();
  const canUseMaintenance = await checkFlag("maintenance-page");
  const canSeeAwards = await checkFlag("visible-awards");
  const canSeeDogeTracker = await checkFlag("can-see-doge-tracker");
  const canSeePipelines = await checkFlag("can-see-pipelines");
  const canFilterContracts = await checkFlag("can-filter-contracts");
  const canSeeFestiveLogo = await checkFlag("can-see-festive-logo");
  const canSeeOpportunitiesAtSearch = await checkFlag("can-see-opportunities-at-search");
  const canSeeAwardsAtSearch = await checkFlag("can-see-awards-at-search");
  const canSeeGlobalSearch = await checkFlag("can-see-global-search");
  const canSeePSC = await checkFlag("can-see-psc-page");
  const canSeeNAICS = await checkFlag("can-see-naics-page");
  measurements.push({
    operation: "Initial feature flags check",
    duration: performance.now() - flagStart,
  });

  // Redirect to the maintenance page if the canUseMaintenance flag is true
  if (canUseMaintenance && url.pathname !== "/maintenance" && isLDEnabled) {
    throw new Response(null, {
      status: 302,
      headers: {
        Location: "/maintenance",
      },
    });
  }

  // get data
  const loaderData: LoaderData = {
    user: null,
    userIsActive: false,
    ENV: env,
    platform,
    activePlatforms: [],
    isloggedin: false,
    token: null,
    canSeeAwards,
    canSeePipelines,
    canSeeFestiveLogo,
    canSeeOpportunitiesAtSearch,
    canSeeAwardsAtSearch,
    canSeeGlobalSearch,
    canFilterContracts,
    canSeeDogeTracker,
    canSeePSC,
    canSeeNAICS,
  };

  const sessionStart = performance.now();
  const hasSession = await session.check();
  measurements.push({
    operation: "Session check",
    duration: performance.now() - sessionStart,
  });

  if (hasSession) {
    const userValidateStart = performance.now();
    const user = await session.validate();
    measurements.push({
      operation: "Session validation",
      duration: performance.now() - userValidateStart,
    });

    const userSetupStart = performance.now();
    loaderData.user = user;
    loaderData.activePlatforms = user.activePlatforms();
    setUser({ email: user.data?.email });
    loaderData.userIsActive = user.isActive();
    loaderData.isloggedin = !user.anonymous;
    measurements.push({
      operation: "User data setup",
      duration: performance.now() - userSetupStart,
    });

    const featureFlagsStart = performance.now();
    loaderData.canSeeDogeTracker = await user.canUse("can-see-doge-tracker");
    loaderData.canSeeAwards = await user.canUse("visible-awards");
    loaderData.canSeePipelines = await user.canUse("can-see-pipelines");
    loaderData.canFilterContracts = await user.canUse("can-filter-contracts");
    loaderData.canSeeOpportunitiesAtSearch = await user.canUse("can-see-opportunities-at-search");
    loaderData.canSeeAwardsAtSearch = await user.canUse("can-see-awards-at-search");
    loaderData.canSeeGlobalSearch = await user.canUse("can-see-global-search");
    loaderData.canSeePSC = await user.canUse("can-see-psc-page");
    loaderData.canSeeNAICS = await user.canUse("can-see-naics-page");
    measurements.push({
      operation: "User feature flags check",
      duration: performance.now() - featureFlagsStart,
    });
  }

  const tokenStart = performance.now();
  loaderData.token = await getSessionToken(request);
  measurements.push({
    operation: "Token fetch",
    duration: performance.now() - tokenStart,
  });

  // Sort measurements by duration in descending order
  measurements.sort((a, b) => b.duration - a.duration);

  // Calculate total duration
  const totalDuration = performance.now() - startTime;

  // Create a structured log object
  const performanceLog = {
    type: "ROOT_LOADER_PERFORMANCE_PROFILE",
    metadata: {
      timestamp: new Date().toISOString(),
      url: url.pathname,
      sessionStatus: hasSession ? "HAS_SESSION" : "NO_SESSION",
      platform,
      deployEnv,
      measurementUnit: "milliseconds",
      precision: "microseconds",
      timeSource: "performance.now",
    },
    performance: {
      totalDurationMs: Number(totalDuration.toFixed(2)),
      measurements: measurements.map(({ operation, duration }) => ({
        operation,
        durationMs: Number(duration.toFixed(2)),
        percentageOfTotal: Number(((duration / totalDuration) * 100).toFixed(1)),
      })),
    },
  };

  // Log in JSON format with a specific prefix for easy searching
  console.log(`[PERF_PROFILE] ${JSON.stringify(performanceLog)}`);

  return loaderData;
});

export function useRootLoaderData() {
  return useRouteLoaderData<LoaderData>("root");
}

function App() {
  const location = useLocation();
  const scrollPositions = useRef<Map<string, number>>(new Map());

  const matches = useMatches();

  // Find the data from the child route that has an id matching "$platform.posts.$postId"
  const postMatch = matches.find((m) => m.id === "routes/$platform.posts.$postId");
  const eventMatch = matches.find((m) => m.id === "routes/events.$eventId");
  const companyMatch = matches.find((m) => m.id === "routes/companies.$companyId");
  const postLoaderData = postMatch?.data as PostLoader | undefined;
  const eventLoaderData = eventMatch?.data as EventLoader | undefined;
  const companyLoaderData = companyMatch?.data as any | undefined;

  useEffect(() => {
    const handleScroll = () => {
      scrollPositions.current.set(location.key, window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.key]);

  useEffect(() => {
    const savedScrollPosition = scrollPositions.current.get(location.key);
    if (savedScrollPosition !== undefined) {
      window.scrollTo(0, savedScrollPosition);
    }
  }, [location]);

  // feeding argument values to initialize tag manager
  useEffect(() => {
    analytics(
      window,
      document,
      "script",
      "dataLayer",
      "GTM-K839NR2",
      user?.data?.id,
      user?.data?.fullName,
      user?.data?.email,
      user?.data?.paymentaccounts?.[0]?.companyAccount?.companyName,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get loader data
  const { user, ENV: loadedEnv } = useLoaderData<LoaderData>() as LoaderData;
  const [LDProvider, setLDProvider] = useState<React.ComponentType<{
    children: React.ReactNode;
  }> | null>(null);

  // Initialize LaunchDarkly before rendering
  useEffect(() => {
    if (!loadedEnv.LAUNCH_DARKLY_CLIENT_SIDE_ID) return;

    const initLD = () => {
      const isAnonymous = user?.anonymous || !user?.id;
      const ldUser = !isAnonymous
        ? {
            key: user.id,
            firstName: user.data?.firstName,
            lastName: user.data?.lastName,
            email: user.data?.email,
            custom: {
              roles: user.data?.roles?.map((r) => r.name),
              isVerified: user.data?.isVerified,
              hasActiveSubscription: user.data?.hasActiveSubscription,
              createdAt: user.data?.createdAt,
            },
          }
        : { anonymous: true };

      // Remove the await and use .then() to keep it non-blocking
      initializeLDClient(loadedEnv.LAUNCH_DARKLY_CLIENT_SIDE_ID, ldUser)
        .then((ProviderComponent) => {
          if (ProviderComponent) {
            setLDProvider(() => ProviderComponent);
          }
        })
        .catch((err) => {
          console.error("LaunchDarkly initialization failed:", err);
        });
    };

    // Use requestIdleCallback to not block the main thread
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(initLD);
    } else {
      setTimeout(initLD, 0);
    }
  }, [loadedEnv.LAUNCH_DARKLY_CLIENT_SIDE_ID, user?.id]);

  // Initialize analytics
  useEffect(() => {
    posthog.init(loadedEnv.POSTHOG_PUBLIC_KEY, {
      mask_all_text: false, // This disables masking all text by default (Except Passwords)
      mask_all_element_attributes: false, // This disables masking select/option elements

      capture_pageview: true,
      loaded: (posthog) => {
        // Unmask email fields
        const unmaskEmailFields = () => {
          const emailFields = document.querySelectorAll('input[type="email"]');
          for (const field of emailFields) {
            field.addEventListener("blur", () => {
              field.setAttribute("data-ph-capture", "true");
            });
          }
        };

        // Initial unmasking
        unmaskEmailFields();

        // Unmask email fields whenever the DOM changes
        const observer = new MutationObserver(unmaskEmailFields);
        observer.observe(document.body, { childList: true, subtree: true });

        // Identify the user if you have user details available (this will help us instead of showing long user ids in the dashboard)
        if (user?.id) {
          posthog.identify(user.id, {
            email: user.data?.email,
            name: user.data?.fullName,
          });
        }
      },
    });

    const gtmScript = document.getElementById("gtm");
    if (gtmScript) {
      gtmScript.innerHTML = gtm;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Capture pageviews
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  // Identify user
  useEffect(() => {
    if (user?.id) {
      posthog.identify(user.id);
    }
  }, [user]);

  useEffect(() => {
    // Once user data is available, we setup Intercom
    if (user) {
      setupIntercom({
        name: user.data?.fullName, // Adjusting according to actual user data structure
        user_id: user.id,
        email: user.data?.email,
        createdAt: user.data?.createdAt, // we were not fetching createdAt before, started fetching now.
      });
    } else {
      setupIntercom({
        user_id: user?.id, // for annonymous users (required by intercom)
      });
    }
  }, [user]);

  const AppContent = (
    <SpartaProvider>
      <StateBanner />
      <Search>
        <AppLayout>
          <div
            id="viewport"
            style={{
              paddingTop: 0,
            }}
          >
            <OverlaySearch />
            <Outlet />
            <Footer />
          </div>
        </AppLayout>
      </Search>
      <ScrollRestoration />
      <div id="portal" style={{ position: "fixed", left: 0, top: 0, zIndex: 9999 }} />
      <script
        // biome-ignore lint/security/noDangerouslySetInnerHtml: required
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(loadedEnv)}`,
        }}
      />
      <Scripts />
    </SpartaProvider>
  );

  return (
    <html lang="en">
      <head>
        <script id="gtm" />
        <Meta />
        <Links />
        {postLoaderData ? <PostSEO loaderData={postLoaderData} /> : null}
        {eventLoaderData ? <EventSEO loaderData={eventLoaderData} /> : null}
        {companyLoaderData ? <CompanySEO loaderData={companyLoaderData} /> : null}
      </head>
      <body>
        <noscript>
          <iframe
            title="gtm"
            src="https://www.googletagmanager.com/ns.html?id=GTM-K839NR2"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
        <span aria-hidden data-message="Made with love - a team of badass devs" />
        <ApolloProvider client={apolloClient}>
          <FingerprintProvider>{LDProvider ? <LDProvider>{AppContent}</LDProvider> : AppContent}</FingerprintProvider>
        </ApolloProvider>
      </body>
    </html>
  );
}

export default withSentry(App);

/**
 * Boundary for catching Server errors
 *
 * @returns A component that displays the error.
 */
interface IErrorData {
  statusText: string;
  data: string;
}

interface CatchBoundaryProps {
  error: IErrorData; // Error data passed as a prop
}

export function CatchBoundary({ error }: CatchBoundaryProps) {
  useCatchLog(error);

  return <ServerError caught={error} />;
}

/**
 * Boundary for catching errors in React components.
 *
 * @param error - The error that was thrown.
 * @returns A component that displays the error.
 */
export function ErrorBoundary() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status === 404) {
      navigate("/oops-not-found");
    }
  }, [error]);
  captureRemixErrorBoundaryError(error);
  return <ServerError caught={{ status: 569, data: "Client Failure" }} />;
}
