import { Cross2Icon } from "@radix-ui/react-icons";
import { useNavigation } from "@remix-run/react";
import { AnimatePresence, motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { Button, Card, Flex } from "sparta";
import { useRootLoaderData } from "~/root";
import { useHotkeys } from "~/utils/hooks/useHotkeys";
import OverlayContent from "./overlayContent";

export default function OverlaySearch() {
  const overlaySearch = useGlobalSearchOverlay();
  const navigation = useNavigation();
  const { canSeeGlobalSearch } = useRootLoaderData();
  // Get Query applied
  const { query } = useSearchBox();

  // Close Global Search when clicking esc key

  useHotkeys([
    [
      "Escape",
      () => {
        if (overlaySearch.open) {
          overlaySearch.setOpen(false);
        }
      },
    ],
  ]);

  useEffect(() => {
    if (!query) {
      overlaySearch.setOpen(false);
    }

    if (navigation.state !== "idle") {
      overlaySearch.setOpen(false);
    }
  }, [query, navigation.state]);

  const styles: any = {
    boxShadow: "2px 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    padding: "0",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "12",
  };
  if (!canSeeGlobalSearch) return null;
  return (
    <AnimatePresence>
      {overlaySearch.open && (
        <motion.div
          key="modal"
          style={styles}
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
        >
          <Card style={{ borderRadius: "0", height: "100%", backgroundColor: "var(--color-panel-solid)" }}>
            <Flex direction="column" width="100%" height="100%">
              <Flex align="center" justify="end">
                <Button variant="ghost" onClick={() => overlaySearch.setOpen(false)}>
                  <Cross2Icon />
                </Button>
              </Flex>
              <Flex style={{ flex: "1" }}>
                <OverlayContent />
              </Flex>
            </Flex>
          </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const globalSearchIsOpenAtom = atom<boolean>(false);

type IGlobalSearchOverlay = {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggle: () => void;
};

// This function returns an object with the current state of the delete search modal
export const useGlobalSearchOverlay = (): IGlobalSearchOverlay => {
  // Get the current state of the modal from the atom
  const [open, setOpen] = useAtom(globalSearchIsOpenAtom);

  // Return an object with the current state of the modal and functions to update it
  return {
    open,
    setOpen,
    toggle: () => setOpen(!open),
  };
};
