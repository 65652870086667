import React from "react";
import useSWR from "swr";
import { fetcher } from "~/utils/fetcher";

interface CompanySEOProps {
  loaderData: {
    company: any;
  };
}

export default function CompanySEO({ loaderData: { company } }: CompanySEOProps) {
  const isBrowser = typeof window !== "undefined";
  const isLocal = isBrowser && window.location.hostname.includes("localhost");
  const baseUrl = isLocal ? "http://localhost:3000" : "https://app.g2xchange.com";

  const url = `${baseUrl}/companies/${company?.company?.uei || ""}`;

  const description =
    company?.company?.about?.document?.[0]?.children?.[0]?.text || "Company description is not available";

  const addresses = (company?.company?.addresses || []).map((addr: any) => ({
    "@type": "PostalAddress",
    streetAddress: addr?.streetAddress,
    addressLocality: addr?.city,
    addressRegion: addr?.state,
    postalCode: addr?.postalCode,
    addressCountry: addr?.country || "US",
  }));

  const contactPoints = (company?.company?.contacts || []).map((contact: any) => ({
    "@type": "ContactPoint",
    contactType: contact?.type || "customer service",
    email: contact?.email,
  }));

  const members = (company?.company?.members || []).map((person: any) => ({
    "@type": "Person",
    name: person?.name,
    email: person?.email,
  }));

  // additional properties
  const additionalProperties = [
    {
      "@type": "PropertyValue",
      name: "Unique Entity ID (UEI)",
      value: company?.company?.uei,
    },
    {
      "@type": "PropertyValue",
      name: "CAGE/NCAGE",
      value: company?.company?.cage,
    },
    {
      "@type": "PropertyValue",
      name: "Primary NAICS",
      value: company?.company?.summary?.primary_naics || "",
    },
    {
      "@type": "PropertyValue",
      name: "Expiration Date",
      value: company?.company?.summary?.expiration_date || "",
    },
    {
      "@type": "PropertyValue",
      name: "Socioeconomic Statuses",
      value: company?.company?.summary?.disadvantageStatuses || "",
    },
  ];

  // Now building JSON-LD object
  const scriptData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: company?.company?.companyName || "N/A",
    url,
    description,
    numberOfEmployees: company?.company?.employeeCount,
    logo: company?.company?.logo?.url,
    address: addresses,
    contactPoint: contactPoints,
    member: members,
    additionalProperty: additionalProperties,
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptData) }} />;
}
