import { Link } from "@remix-run/react";
import { Anchor, Button, Flex, Heading, TableV2 } from "sparta";
import { formatDate } from "~/routes/awards.vehicles._index/components/utils";
import GeneralLoader from "./generalLoader";
import { useGlobalSearchOverlay } from "./main";

interface IOpportunities {
  data: any[];
  isLoading: boolean;
  link: string;
  visible: boolean;
}

export default function Opportunities({ data, isLoading, link, visible }: IOpportunities) {
  const overlaySearch = useGlobalSearchOverlay();

  if (data.length <= 0 || !visible) {
    return null;
  }

  return (
    <Flex id="opportunities" direction="column" py="3" gap="4">
      <Heading size={"3"}>Opportunities</Heading>
      <TableV2.Root variant="surface">
        <TableV2.Header>
          <TableV2.Row>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Title
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Agency
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Solicitation Number
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Response Due Date
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
          </TableV2.Row>
        </TableV2.Header>
        <TableV2.Body>
          {data?.map((x) => {
            const responseDeadline: string = formatDate(x.response_deadline);
            return (
              <TableV2.Row>
                <TableV2.Cell minWidth={"330px"}>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    <Anchor asChild>
                      <Link to={`/opportunities/${x.opportunity_id}`}>{x.title || "-"}</Link>
                    </Anchor>
                  </GeneralLoader>
                </TableV2.Cell>
                <TableV2.Cell minWidth={"80px"}>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    {x.agency?.name || "-"}
                  </GeneralLoader>
                </TableV2.Cell>
                <TableV2.Cell>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    {x.solicitation_number || "-"}
                  </GeneralLoader>
                </TableV2.Cell>
                <TableV2.Cell>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    {x.responseDeadline || "-"}
                    <Flex
                      width="10px"
                      height="10px"
                      style={{
                        borderRadius: "100%",
                        backgroundColor: x.active ? "var(--jade-10)" : "var(--red-10)",
                      }}
                    />
                  </GeneralLoader>
                </TableV2.Cell>
              </TableV2.Row>
            );
          })}
        </TableV2.Body>
      </TableV2.Root>
      <Flex align="center" justify="center">
        <Link
          onClick={() => {
            overlaySearch.setOpen(false);
          }}
          to={link}
        >
          <Button color="gray" variant="soft">
            See all results
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}
